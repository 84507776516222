import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types';
import ImpactCheckout from './ImpactCheckout';
import {
  BrowserView,
  MobileView } from "react-device-detect";
import 'react-phone-number-input/style.css';
import './InfoForm.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class InfoForm extends React.Component {
   constructor(props) {
    super(props);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitAndContinueToPayment = this.handleSubmitAndContinueToPayment.bind(this);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      orgId: '',
      orgs: [],
      validated: false,
      successfullyValidated: false,
      hasErrors: false,
      showSpinner: false
    };
  }

  async componentDidMount() {
    let orgs = await fetch('https://xujrgzg7q4.execute-api.us-east-1.amazonaws.com/live/getOrgs');
    orgs = await orgs.json();
    this.setState({orgs: !!orgs.orgs ? orgs.orgs : []});
  }

  handleBackButton() {
    this.setState({successfullyValidated: false});
  }

  handleChange(event) {
    const { target } = event;
    const { value, name } = target;

    this.setState({
        [name]: value
    });
  }

  handleSubmitAndContinueToPayment(event) {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({validated: true, hasErrors: true})
    } else {
      this.setState({ validated: true, successfullyValidated: true, hasErrors: false })
    }
  }

  render () {
    const { firstName, lastName, email, showSpinner, hasErrors, orgs, orgId } = this.state;
    const displayPayButton = this.state.successfullyValidated;
    const validationError = (<Alert key={"alert-1"} variant={"danger"}>One or more fields below, highlighted in red, have errors. Please verify your information and try again</Alert>);
    return (
      <React.Fragment>
      <BrowserView>
        <div className="family-info-form">
           {!displayPayButton && <strong>Patient Information</strong>}
           {hasErrors && validationError}
           {!displayPayButton && 
            <Form noValidate validated={this.state.validated} className="info-form" onSubmit={this.handleSubmitAndContinueToPayment}>
            <Row>
              <Col>
                <Form.Control required type="text"
                  placeholder="First name"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange} />
              </Col>
              <Col>
                <Form.Control required type="text"
                  placeholder="Last name"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange} />
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <Form.Control required type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleChange} />
              </Col>
            </Row>
            <br/>
            <Row>
                <Col style={{"marginTop": "10px"}}>
                    <Form.Control value={orgId} required style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="orgId" onChange={this.handleChange} className="form-control">
                      {<option value="">Select Organization...</option>}
                      {orgs.map((org) => 
                        <option key={`option-${org.orgId}-desktop`} value={org.orgId}>{org.orgName}</option>
                      )}
                    </Form.Control>
                </Col>
            </Row>
            <Row className="justify-content-center continue-to-payment">
              <button className="btn btn-primary" type="submit">Continue to Payment</button>
            </Row>
            {!displayPayButton && 
              <Row className="justify-content-center payment-disclaimer">
                  <small>
                    <i>
                        Your card will be charged $50.00 for an Impact Baseline Test code, sent to your email.
                    </i>
                  </small>
              </Row>
            }
          </Form>}
          {displayPayButton && 
            <React.Fragment>
              <div className="justify-content-center" hidden={!showSpinner}>
                <Spinner animation="border" variant="info" />
              </div>
              <Row hidden={showSpinner} className="back-button">
                <button className="btn btn-secondary btn-sm" type="button" onClick={this.handleBackButton}>Back</button>
              </Row>
              <Row className="justify-content-center" hidden={showSpinner}>
                <ImpactCheckout
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  orgId={orgId}
                  onPayment={this.props.onPayment}
                  toggleLoading={(showLoading) => this.setState({ showSpinner: showLoading })}
                />
              </Row>
            </React.Fragment>
          }
        </div>
      </BrowserView>
      <MobileView>
         <div className="form-mobile">
           {!displayPayButton && <strong>Customer Information</strong>}
           {hasErrors && validationError}
           {!displayPayButton && 
            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitAndContinueToPayment}>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                First Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="text"
                  placeholder="First name"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Last Name
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="text"
                  placeholder="Last name"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Email
              </Form.Label>
              <Col sm={6}>
                <Form.Control required type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    Organization
                </Form.Label>
                <Col sm={6}>
                    <Form.Control value={orgId} required style={{"fontFamily": "'Source Code Pro', monospace", "boxShadow": "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"}} as="select" name="orgId" onChange={this.handleChange}>
                      {<option value="">Select Organization...</option>}
                      {orgs.map((org) => 
                        <option key={`option-${org.orgId}-desktop`} value={org.orgId}>{org.orgName}</option>
                      )}
                    </Form.Control>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={6}><button className="btn btn-primary" type="submit">Continue to Payment</button></Col>
            </Form.Group>
            {!displayPayButton && 
              <Row className="justify-content-center payment-disclaimer">
                <Col sm={6}>
                  <small>
                    <i>
                    Your card will be charged $50.00 for an Impact Baseline Test code, sent to your email.
                    </i>
                  </small>
                </Col>
              </Row>
            }
          </Form>}
          {displayPayButton && 
            <React.Fragment>
              <div className="justify-content-center" hidden={!showSpinner}>
                <Spinner animation="border" variant="info" />
              </div>
              <Row hidden={showSpinner} className="back-button">
                <button className="btn btn-secondary btn-sm" type="button" onClick={this.handleBackButton}>Back</button>
              </Row>
              <Row className="justify-content-center" hidden={showSpinner}>
                <Col sm={6}>
                  <ImpactCheckout
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    orgId={orgId}
                    onPayment={this.props.onPayment}
                    toggleLoading={(showLoading) => this.setState({ showSpinner: showLoading })}
                    isMobile={true}
                  />
                </Col>
              </Row>
            </React.Fragment>
          }
        </div>
      </MobileView>
      </React.Fragment>
    );
  }
}

InfoForm.propTypes = {
  onPayment: PropTypes.func,
};

export default InfoForm;