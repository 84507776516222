// MyStoreCheckout.js
import React from 'react';
import {Elements} from 'react-stripe-elements';
import PropTypes from 'prop-types';
import InjectedCheckoutForm from './CheckoutForm';
import './ImpactCheckout.css';

class ImpactCheckout extends React.Component {
  render() {
    return (
      <div>
	      <Elements>
	        <InjectedCheckoutForm
	        	{...this.props}
	         />
	      </Elements>
      </div>
    );
  }
}

ImpactCheckout.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	orgId: PropTypes.string,
	onPayment: PropTypes.func,
	toggleLoading: PropTypes.func,
	isMobile: PropTypes.bool
};

export default ImpactCheckout;