// CardSection.js
import React from 'react';
import {CardElement} from 'react-stripe-elements';
import { Col, Row } from 'react-bootstrap';

import './CardSection.css';

class CardSection extends React.Component {

  render() {
    const createOptions = (fontSize, padding) => {
      const styles = {
          style: {
            base: {
              fontSize,
              color: '#424770',
              letterSpacing: '0.025em',
              fontFamily: 'Source Code Pro, monospace',
              '::placeholder': {
                color: '#aab7c4',
              },
              padding,
            },
            invalid: {
              color: '#9e2146',
            },
          },
        };
        return styles;
      };


    return (
      <div className={this.props.isMobile ? "mobile-card-section" : ""}>
        <label>Card details</label>
        <CardElement className={"form-row flex-column " + (this.props.isMobile ? "mobile-card-section" : "")} {...createOptions(this.props.fontSize, null)}/>
        <Row className="justify-content-center payment-disclaimer">
            <Col>
              <small>
                <i>
                    Your card will be charged $50.00, renewed annually, for access to the advice line service from the phone number(s) entered above.
                </i>
              </small>
            </Col>
          </Row>
      </div>
    );
  }
}

export default CardSection;