import React from 'react';
import {StripeProvider} from 'react-stripe-elements';
import InfoForm from './InfoForm';
import SuccessMessage from './SuccessMessage';
import './App.css';
import config from './config';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.handlePayment = this.handlePayment.bind(this);
        this.state = {
            allowedNumbers: [],
            successMessage: '',
            subscriptionStart: null,
            subscriptionEnd: null
        }
    }

    handlePayment(resultData) {
        this.setState({
            successMessage: !!resultData ? resultData.message : '',
            code: !!resultData && !!resultData.code && !!resultData.code.purchases && !!resultData.code.purchases[0] ? resultData.code.purchases[0].redemptionCode : '',
            email: !!resultData && !!resultData.email ? resultData.email : ''
        });
    }

    render () {
        return (
            <StripeProvider apiKey={config.stripe.apiKey}>
                <div className="root">
                    <div className="logo-heading">
                        <img alt="1-to-1 Pediatrics Logo" src={require('./Logo.png')}/>
                        <h2 className="page-title">1-to-1 Pediatrics</h2>
                        <h4>ImPACT Baseline</h4>
                        <p><i>Once this form is completed, you will be emailed a link to your baseline ImPACT.</i></p>
                        <hr/>
                    </div>
                    {!this.state.successMessage ? <InfoForm onPayment={this.handlePayment} /> : 
                        <SuccessMessage
                            allowedNumbers={this.state.allowedNumbers}
                            code={this.state.code}
                            email={this.state.email} />
                    }
                </div>
            </StripeProvider>
        );
    }
}

export default App;
