import React from 'react';
import PropTypes from 'prop-types';
import './SuccessMessage.css';

class SuccessMessage extends React.Component {
    render () {
        return (
			<div className="success-message">
                <h3>Thank you for signing up for ImPACT!</h3>
                <p>Your test code is <strong>{this.props.code}</strong> and has been sent to the following email: <i>{this.props.email}</i></p>
            </div>
        );
    }
}

SuccessMessage.propTypes = {
  email: PropTypes.string,
  code: PropTypes.string
};

export default SuccessMessage;